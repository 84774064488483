<template>
  <div>
    <dx-data-grid
      ref="wfsTemplatesGridRef"
      :data-source="templateDataSource"
      :show-borders="true"
      class="mt-2"
      :height="'calc(100vh - 360px)'"
      :column-auto-width="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :hover-state-enabled="true"
      :row-alternation-enabled="false"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
    <dx-column data-field="name" />
    <dx-column data-field="type" />
    <dx-column data-field="status" />
    <dx-column data-field="rateModelType" />
    <dx-column data-field="rowActions" width="120" cell-template="rowActionsTemplate" />
    <template #rowActionsTemplate="{data}">
      <div class="d-flex">
        <dx-util-button icon="icon bi-eye" type="aqua" class="mr-half" @click="openPopover(data.data.id)" />
        <dx-util-button icon="trash" type="danger" class="mr-half" />
      </div>
    </template>
    <template #filtersToolbar>
      <div class="d-flex">
        <div v-if="hasPermission">
          <dx-util-text-box
            v-model.trim="accountNo"
            :show-clear-button="true"
            mode="text"
            placeholder="Account or Suite No"
            class="mr-half"
            @key-down="onFilterByAccountNo"
            @value-changed="onFilterByAccountNo"
          />
        </div>
        <div>
          <dx-util-select-box
            v-model="selectedStore"
            :data-source="walmartStores"
            class="mr-half"
            display-expr="text"
            value-expr="value"
            @value-changed="searchByFilter"
          />
        </div>
      </div>
    </template>
    </dx-data-grid>
    <wfs-template-details :component-id="wfsTemplateDetailsId" :template-id="selectedTemplateId" :store-id="selectedStore" />
  </div>
</template>

<script>
import useStores from '@/@core/composables/useStores'
import {
// ref, // computed, watch, reactive,
// onMounted,
  watchEffect,
} from '@vue/composition-api'
import { uniqueId } from 'lodash'
import useWfsTemplates from './useWfsTemplates.js'

const PLATFORM = 'UNKNOWN' // WALMART BU ŞEKİLDE GÖRÜNÜYOR
export default {
  components: {
    'wfs-template-details': () => import('./components/WfsTemplateDetails.vue'),
  },
  data() {
    return {
      PLATFORM,
      selectedRowId: null,
      popupVisible: false,
      wfsTemplateDetailsId: '',
      selectedTemplateId: null,
    }
  },
  setup() {
    const {
      templateDataSource,
      accountNo, store,
      getTemplateDetails,
      getTemplatesByStoreId,
      templateDetails,
    } = useWfsTemplates()

    const {
      getStores, selectedStore, isLoading, stores, walmartStores,
    } = useStores()

    watchEffect(() => {
      selectedStore.value = walmartStores.value[0].value
      getTemplatesByStoreId(selectedStore.value)
    })

    return {
      templateDataSource,
      templateDetails,
      getTemplateDetails,
      getTemplatesByStoreId,
      accountNo,
      store,
      walmartStores,
      getStores,
      isLoading,
      selectedStore,
      stores,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    searchByFilter() {
    },
    async openPopover(templateId) {
      this.selectedTemplateId = templateId
      await this.getTemplateDetails(this.selectedStore, templateId)
      this.wfsTemplateDetailsId = uniqueId()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.selectedStore = null
        this.store = null
        this.getStores(this.accountNo)
        this.searchByFilter()
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      // const tempToolbarItems = [...toolbarItems]
      // toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'filtersToolbar',
      })

      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            // this.loadData(false)
          },
        },
        location: 'after',
      })

      // tempToolbarItems.forEach(item => {
      //   toolbarItems.push(item)
      // })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
