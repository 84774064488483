import {
  ref, // computed, watch, reactive,
  computed,
  // watchEffect,
} from '@vue/composition-api'
import commonEntityService from '@/http/requests/common/commonEntityService'

export default function useStores() {
  const stores = ref([])
  const selectedStore = ref(null)

  function setStores(storeData) {
    stores.value.splice(0, stores.value.length)
    storeData.forEach(element => {
      stores.value.push({
        value: element.id,
        text: element.name,
        companyId: element.parentCompany,
        isAuthorized: element.isAuthorized,
        commercePlatform: element.commercePlatform,
      })
    })

    if (stores.value.length > 1) {
      stores.value.unshift({
        value: 0,
        text: 'All Stores',
      })
    }
    // İlk öğe yüklenerek tek store varsa o store, birden fazla store varsa hepsi seçeneği seçilmiş oluyor.
    if (stores.value.length > 0) {
      selectedStore.value = stores.value[0]?.value
    }
  }

  async function getStoresByAccountNo(accNo) {
    const result = await commonEntityService.fetchStoresByParentCompanyAccountNo(accNo)
    const fetchedStores = result.data
    setStores(fetchedStores)
  }

  async function getCurrentCompanyStores() {
    const result = await commonEntityService.fetchStoresForCurrentCompany()
    const fetchedStores = result.data
    setStores(fetchedStores)
  }

  async function getStores(accountNo = null) {
    if (accountNo) {
      await getStoresByAccountNo(accountNo)
      return
    }
    await getCurrentCompanyStores()
  }

  const walmartStores = computed(() => stores.value.filter(el => el.commercePlatform === 'Walmart'))

  return {
    stores,
    getStores,
    selectedStore,
    walmartStores,
  }
}
