import {
  ref, // computed, watch, computed, reactive, onMounted,
  // onMounted,
} from '@vue/composition-api'
import wfsService from '@/http/requests/wfs'

const templateDetails = ref({})

export default function useWfsTemplates() {
  const templateDataSource = ref([])

  async function getTemplatesByStoreId(storeId) {
    if (!storeId) {
      return
    }
    const result = await wfsService.getShippingTemplates(storeId)
    templateDataSource.value = result.shippingTemplates
  }

  async function getTemplateDetails(storeId, templateId) {
    const result = await wfsService.getShippingTemplateDetails(storeId, templateId)
    templateDetails.value = result
  }

  return {
    templateDataSource,
    templateDetails,
    getTemplatesByStoreId,
    getTemplateDetails,
  }
}
